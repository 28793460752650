<template>
  <v-col cols="12">
    <base-card>
      <v-card-title>
        Generador de informe de documentos pendientes
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col
              cols="12"
            >
              El informe solo podrá generarse dentro de un rango de 90 días.
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="4"
            >
              <v-dialog
                ref="dialog"
                v-model="modal"
                persistent
                width="300px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="processedDocsData.dateRange"
                    label="Rango de fechas"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    :error = dateRangeError
                    :error-messages = "dateRangeErrorMessage"
                    :rules="[
                  v => !!v || 'Este campo es obligatorio'
                ]"
                  />
                </template>

                <v-date-picker
                  v-model="processedDocsData.dateRange"
                  range
                  :min="minDate"
                  :max="maxDate"
                  :disabled-dates="disabledDates"
                  @input="onDateRangeInput"
                >
                  <v-spacer />
                  <v-btn
                    text
                    style="background-color: rgb(204 33 40); color: white !important"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    style="background-color: rgb(204 33 40); color: white !important"
                    @click="onAccept"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12" sm="4">
              <v-text-field
                ref="Nit"
                v-model="processedDocsData.email"
                label="Correo"
                counter
                maxlength="80"
                prepend-inner-icon="mdi-email"
                required
                :rules="[
                v => !!v || 'Este campo es obligatorio',
                v => /.+@.+\..+/.test(v) || 'El correo no es válido'
              ]"
              />
            </v-col>

            <v-col cols="12" sm="1"/>

            <v-col cols="12" sm="3">
              <v-btn
                class="ma-2"
                :style="generateButtonStyle"
                @click="validateForm"
                :disabled = "generatingReport"
              >
                Generar
              </v-btn>

              <v-btn
                class="ma-2"
                color="GRAY"
                @click="clearForm"
                :disabled = "generatingReport"
              >
                Limpiar
              </v-btn>
            </v-col>

          </v-row>

          <v-row>
            <v-col
              cols="12"
            >
              <v-alert
                v-show="successAlert"
                type="success"
                text
              >
                {{ successAlertMsg }}
              </v-alert>

              <v-alert
                v-show="errorAlert"
                type="warning"
                text
              >
                {{ errorAlertMsg }}
              </v-alert>
            </v-col>
          </v-row>

        </v-form>
      </v-card-text>
    </base-card>
  </v-col>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'pendingDocsFilter',

  data () {
    return {
      modal: false,
      generatingReport: false,
      processedDocsData: [],
      dataUpdateFabricante: [],
      formHasErrors: null,
      dateRangeError: null,
      dateRangeErrorMessage: [],
      successAlertMsg: '',
      successAlert: false,
      errorAlert: false,
      errorAlertMsg: '',
    }
  },
  computed: {
    ...mapState('clients', [
      'statusGeneratePendingReportStatus', 'statusGeneratePendingReportMessage'
    ]),
    minDate() {
      const today = new Date()
      const minDate = new Date(today)
      minDate.setDate(today.getDate() - 90)
      return minDate.toISOString().split('T')[0]
    },
    maxDate() {
      const today = new Date()
      return today.toISOString().split('T')[0]
    },
    disabledDates() {
      const minDate = this.minDate;
      return (date) => {
        const dateString = date.toISOString().split('T')[0];
        return dateString < minDate;
      };
    },
    setStatusResponseDownloadPendingReportFile () {
      return this.$store.state.clients.statusGeneratePendingReportStatus
    },
    setStatusResponseDownloadPendingReportFileMsg () {
      return this.$store.state.clients.statusGeneratePendingReportMessage
    },
    generateButtonStyle() {
      return {
        backgroundColor: this.generatingReport ? 'gray' : 'rgb(204 33 40)',
        color: this.generatingReport ? 'lightgray' : 'white',
      };
    },
  },
  watch:
    {
      formHasErrors () {
        if (this.formHasErrors === false) {
          this.generateReport()
        }
      },
      setStatusResponseDownloadPendingReportFile () {
        if (this.setStatusResponseDownloadPendingReportFile === true) {
          this.generateAlertSuccess(this.setStatusResponseDownloadPendingReportFileMsg)
          this.$store.dispatch('clients/setStatusResponseDownloadPendingReportFile', null)
        } else if (this.setStatusResponseDownloadPendingReportFile === false) {
          this.generateErrorAlert(this.setStatusResponseDownloadPendingReportFileMsg)
          this.$store.dispatch('clients/setStatusResponseDownloadPendingReportFile', null)
        }
      },
      pending_report_dialog(newVal) {
        if (newVal) {
          this.clearForm()
          this.successAlert = false
          this.errorAlert = false
        }
      },
    },
  methods: {
    generateAlertSuccess (msj) {
      this.successAlertMsg = msj
      this.successAlert = true
      setTimeout(() => {
        this.successAlertMsg = ''
        this.successAlert = false
        this.generatingReport = false
        this.clearForm()
      }, 4000)
    },
    generateErrorAlert (msj) {
      this.errorAlertMsg = msj
      this.errorAlert = true
      setTimeout(() => {
        this.errorAlertMsg = ''
        this.errorAlert = false
        this.generatingReport = false
      }, 4000)
    },
    clearForm() {
      this.$refs.form.reset()
      this.dateRangeError = null
      this.dateRangeErrorMessage = []
      this.formHasErrors = null
      this.successAlert = false
    },
    validateForm() {
      if (this.processedDocsData.dateRange[0] === undefined) {
        this.dateRangeError = true
        this.dateRangeErrorMessage = 'Este campo es obligatorio'
      } else {
        this.dateRangeError = false
        this.dateRangeErrorMessage = []
      }
      const isValid = this.$refs.form.validate()

      if (isValid && this.processedDocsData.dateRange[1] !== undefined) {
        this.processedDocsData.todasCompanias = JSON.parse(atob(localStorage.getItem("uid"))).rol === "Administrador";
        this.processedDocsData.idCompania = JSON.parse(atob(localStorage.getItem("uid"))).idcompania
        this.processedDocsData.nombreCompania = 'NN'
        this.generateReport()
      } else {
        this.$emit('error', 'Por favor, complete todos los campos correctamente.')
      }
    },
    async generateReport() {
      this.generatingReport = true
      await this.$store.dispatch('clients/generatePendingInvoicesReport', {
        ...this.processedDocsData
      })
    },
    onDateRangeInput(dateRange) {
      if (dateRange && dateRange.length === 2) {
        const startDate = new Date(dateRange[0])
        const endDate = new Date(dateRange[1])
        const diffInDays = (endDate - startDate) / (1000 * 3600 * 24)

        if (diffInDays >= 90) {
          this.processedDocsData.dateRange = [dateRange[0], this.addDays(new Date(dateRange[0]), 90)]
        }
      }
    },
    addDays(date, days) {
      const newDate = new Date(date)
      newDate.setDate(date.getDate() + days)
      return newDate.toISOString().split('T')[0]
    },
    onAccept() {
      this.modal = false
    },
  },
}
</script>
